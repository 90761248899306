import React, { useCallback, useState } from 'react';
import { Button, Form, Input } from 'antd';
import strings from '../../../utility/strings';
import IdentityService from '../../../config/dataService/identityService';
import { useDispatch } from 'react-redux';
import { login } from '../../../redux/authentication/actionCreator';
import { Link, useHistory } from 'react-router-dom';
import { failedNotif } from '../../../utility/utility';
import FeatherIcon from 'feather-icons-react';
import pages from '../../../config/pages';
import { setItem } from '../../../utility/localStorageControl';
import keys from '../../../utility/keys';
import { setNewTenant } from '../../../redux/tenant/actionCreator';

export default function SignIn() {
  const dispatch = useDispatch();
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(
    async values => {
      setLoading(true);

      try {
        setItem(keys.host, values.server);
        const loginResult = await IdentityService.login(values.email, values.password);

        setLoading(false);
        if (loginResult?.data?.token) {
          dispatch(setNewTenant({ uuid: loginResult.data.metadata.tenant_uuid }));

          dispatch(login());
          history.push(`${pages.dashboard}`);
        }
      } catch (error) {
        setLoading(false);
        failedNotif(strings().operationFailed, strings().WrongAuth);
        return;
      }
    },
    [dispatch, history],
  );

  return (
    <>
      <h3 className="main-title">{strings().identity.welcome}</h3>
      <Form onFinish={handleSubmit}>
        <Form.Item name="server" rules={[{ message: strings().validation.required, required: true }]}>
          <Input placeholder={strings().server} prefix={<FeatherIcon icon="server" size={20} />} />
        </Form.Item>
        <Form.Item name="email" rules={[{ message: strings().validation.required, required: true }]}>
          <Input placeholder={strings().email} prefix={<FeatherIcon icon="user" size={20} />} />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ message: strings().validation.required, required: true }]}
          className="pass-input"
        >
          <Input.Password placeholder={strings().identity.password} prefix={<FeatherIcon icon="lock" size={20} />} />
        </Form.Item>
        {/* <Link to={pages.forgotPassword}>
          <small className="forgot-pass">{strings().identity.forgotPassword}</small>
        </Link> */}
        <Button htmlType="submit" size="large" loading={loading}>
          {strings().identity.signIn}
        </Button>
      </Form>
      <div className="signup-link">
        {/* <p>
          {strings().identity.dontHaveAccount}
          <Link to={pages.signUp}>
            <span>{strings().identity.signUpNow}</span>
          </Link>
        </p> */}
      </div>
    </>
  );
}
